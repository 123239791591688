import React from 'react';
import Layout from '../components/layout';
import Box, { BoxImage, BoxParagraph, BoxTitle, Bibliobox } from '../elements/box';
import Card, { CardContent, CardImage, CardTitle} from '../elements/card';

const La_Supervision = () => (
  <Layout>
    <Box background="primary-light">
        <BoxTitle title="Qu'est ce que la Gestalt ?"/>
        <BoxParagraph>
            <p>Gestalt vient du verbe allemand "gestalten" qui signifie "mettre en forme, donner une structure".</p>
            <p>La Gestalt contemporaine, initialement méthode nouvelle de psychothérapie humaniste existentielle,
                s’est progressivement ouverte à d’autres champs d’application : éducation, soins, institutions et entreprises.</p>
            <p>La Gestalt met l’accent sur la prise de conscience du processus en cours dans l’ici et maintenant de chaque 
                situation ; elle souligne le sens de la responsabilité et réhabilite le ressenti émotionnel, trop souvent 
                encore censuré par la culture occidentale. Elle développe une perspective cohérente de l’être humain, 
                en relation dans un champ, intégrant cinq dimensions principales : sensorielle, affective, intellectuelle, 
                sociale et spirituelle.</p>
            <p>La Gestalt favorise un contact authentique avec les autres, un "ajustement créatif" à l’environnement ainsi 
                qu’une prise de conscience des dysfonctionnements anachroniques qui nous poussent trop souvent à des conduites 
                répétitives. Elle permet le repérage de nos processus de blocage ou d’interruption dans le cycle du contact et 
                dévoile nos inhibitions, nos évitements ainsi que nos illusions persistantes.</p>
            <p>La Gestalt propose, plutôt que d’expliquer les origines de nos difficultés, d’expérimenter des pistes de solution 
                dans la libre construction d’un sens : à la recherche du savoir pourquoi, elle ajoute le sentir comment, 
                mobilisateur de changement.</p>
        </BoxParagraph>
    </Box>
    <div className="columns"> 
        <div className="column">
            <Card>
                <CardContent>
                    <CardTitle title="Les champs d'application" />
                </CardContent>
                <CardContent>
                    <div className="content centered-content">
                        <p>De nos jours, la Gestalt est utilisée : </p>
                        <p><b>En psychothérapie</b> (individuelle, de couple, familiale ou de groupe) <b>:</b></p>
                        <p>pour des personnes souffrant de troubles psychologiques ou psychosomatiques, de problèmes existentiels 
                            (mal-être chronique ou crise passagère) ou désirant mieux assumer une situation particulière 
                            (deuil, séparation, sentiment d’exclusion, etc.)</p>
                        <p><b>En développement personnel :</b></p>
                        <p>pour des personnes recherchant un "plus être”, un épanouissement de leur potentiel latent</p>
                        <p><b>Dans le champ des organisations</b> :
                            <ul>
                                <li>en formation (individuelle ou de groupe)</li>
                                <li>en conseil, auprès d’entreprises ou institutions</li>
                                <li>en coaching individuel, avec des chefs d’entreprise ou des cadres</li>
                            </ul>
                        </p>
                    </div>
                </CardContent>
            </Card>
        </div>
        <div className="column is-4">
            <Card>
                <CardImage src="gestalt2" alt="gestalt" />
            </Card>
        </div>
    </div>
    <Box background="primary-light">
        <BoxTitle title="Bref historique :" />
        <BoxParagraph>
            <p>La Gestalt-thérapie a été élaborée surtout par Fritz Perls, psychanalyste juif d’origine allemande, émigré 
                aux États-Unis à l’âge de 53 ans. Elle s’y est développée lentement dans un premier temps, à partir des années 
                50, avant de prendre souche en Californie.</p>
            <p>Fritz Perls a commencé à élaborer la Gestalt-thérapie aux environs des années 1942. Grâce à la collaboration d'une
                 équipe New-Yorkaise constituée en particulier de Laura Perls et de Paul Goodman, les bases théoriques de la 
                 méthode sont établies en 1951 sur les fondements de la Gestalt-Psychologie, les recherches psychanalytiques 
                 (Freud, Ferenczi, O.Rank, W.Reich), phénoménologiques et existentielles.</p>
            <p>Elle y est devenue célèbre, notamment à l’occasion du mouvement de contre-culture de 68 qui a secoué toute la 
                planète, à la recherche de nouvelles valeurs humanistes de créativité et de liberté, rendant à chacun sa part 
                de responsabilité, revalorisant l’être par rapport à l’avoir, émancipant le savoir par rapport au pouvoir.</p>
            <p>Elle se situe au carrefour de plusieurs courants européens, américains et orientaux : approches phénoménologique
                 et existentielle, psychanalyse, thérapies d’inspiration reichienne, etc. </p>
        </BoxParagraph>
    </Box>
    <Box>
        <BoxTitle title="Bibliographie :" />
        <div className="columns">
            <div className="column">
                <Bibliobox 
                    title="La Gestalt : l’art du contact"
                    references="Serge Ginger - Marabout- Hachette, Paris, 1995"
                    description="Complet et facile à lire, destiné à un large public. Illustré d’exemples, agrémenté d’informations sur le cerveau, les rêves, la sexualité, la psychopathologie et de conseils pratiques. Traduit en sept langues."
                />
            </div>
            <div className="column">
                <Bibliobox 
                    title="La Gestalt Aujourd'hui"
                    references="Gonzague Masquelier - Retz, Paris,1999, 3e édition 2008"
                    description="Un ouvrage écrit pour un large public, avec une vision claire et accessible de la Gestalt. Cinquante années d’histoire de la Gestalt, les principaux concepts illustrés par des cas cliniques ; regards sur la Gestalt en entreprise."
                />
            </div>
            <div className="column">
                <Bibliobox 
                    title="La Gestalt, une thérapie du contact"
                    references="Serge et Anne Ginger - Hommes et Groupes Éditeurs, Paris, 1987"
                    description="L’ouvrage de référence sur la Gestalt, traduit en six langues. Un livre théorique et pratique, illustré de témoignages et d’exemples cliniques, assorti d’annexes techniques (index, tableaux, glossaire, bibliographie)."
                />
            </div>
            <div className="column">
                <Bibliobox 
                    title="Guide pratique du psychothérapeute humaniste"
                    references="S.et A. Ginger - Dunod, Paris, 2008"
                    description="Rédigé dans un style vivant, abondamment illustré de vignettes cliniques, ce livre s’adresse aux psychothérapeutes débutants ou confirmés, mais aussi aux clients désireux de mieux connaître leur itinéraire. Il apporte à la fois des conseils pratiques et une réflexion philosophique."
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Bibliobox 
                    title="Sexualité, amour et Gestalt"
                    references="Brigitte Martel - InterEditions, Paris, 2004"
                    description="Livre présentant l’originalité de la Gestalt dans l’accompagnement de la vie sexuelle. Les principaux questionnements, illustrés par des vignettes cliniques."
                />
            </div>
            <div className="column">
                <Bibliobox 
                    title="Psychothérapie : cent réponses pour en finir avec les idées reçues"
                    references="Serge Ginger - Dunod, Paris, 2006"
                    description="Un panorama provocateur sur les malentendus courants concernant la pratique de la psychothérapie et de la Gestalt et leur place dans la société."
                />
            </div>
            <div className="column">
                <Bibliobox 
                    title="La Gestalt expliquée à tous"
                    references="Chantal Higy Lang et Charles Gellman - Editions Eyrolles, Paris, 2007"
                    description="Ce livre développe l’histoire de la Gestalt et de ses principaux concepts, et explicite certaines facettes de la thérapie, en particulier la SexoGestalt."
                />  
            </div>
            <div className="column">
                <Bibliobox 
                    title="Découvrir la Gestalt-thérapie"
                    references="Martine Périou - InterEditions, Paris, 2008"
                    description="D’un contenu clair et accessible, illustré de nombreux cas cliniques, ce livre développe l’histoire de la Gestalt, ses principaux concepts et sa pratique."
                />
            </div>
        </div>
        <div className="columns">
            <div className="column is-one-quarter">
                <Bibliobox 
                    title="Panser l’impensable"
                    references="Fernande Amblard - Editions Jouvence, Paris,2003"
                    description="Les conséquences des abus sexuels dans l’enfance et le travail thérapeutique possible avec la Gestalt."
                />
            </div>
            <div className="column is-one-quarter">
                <BoxImage src="zebre" alt="zebre" />
            </div>
        </div>
    </Box>
  </Layout>
)

export default La_Supervision;